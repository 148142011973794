import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/nithin.jpg';

const AdvocateNithinDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Mr. Nithin did his LLB from Jindal Global School, New Delhi. He
                enrolled as an Advocate in the year 2015. He is specialised in
                the areas of Corporate Laws, Arbitrations, Environmental Laws
                and Information Technology Laws. He worked at various law firms
                at Delhi. He is also associated with Chambers of Additional
                Solicitor General of India. He is practising curently at Hon’ble
                Supreme Court of India, Hon’ble High Court of Delhi, Telangana
                and Andhra Pradesh and National Company Law Tribunal and
                National Company Law Appellate Tribunal.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>
                  Mr. Nithin Chowdary Pavuluri. B.A., LLB.Hons.
                </h2>
                <p className='team-one__designation'>
                  Partner, Consultant and Advocate
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateNithinDetails;
